<template>
	<div>
		<div class="form-group">
			<button type="button" class="btn btn-primary" v-on:click="inserting = true">Adicionar associado</button>
		</div>
		<div class="form-group" v-if="inserting">
			<div class="row">
				<div class="form-group col-md-6">
					<label>Associado</label>
					<InputSearch inputName="activity-associate" collection="associates" object="associate" fieldKey="id" fieldText="name" current='' @select="(sel) => associate = sel" rows="200" :filter="{inactive: false}"/>
				</div>
				<div class="col-md-3 pt-2">
					<button type="button" class="btn btn-primary mt-4" v-on:click="saveAssociate">Salvar</button>
					<button type="button" class="btn btn-default mt-4" v-on:click="inserting = false">Cancelar</button>
				</div>
			</div>
		</div>
	</div>            
</template>

<script>
import ApiAxios from '../../services/api-axios';
import InputSearch from '../commons/InputSearch.vue';
import { sAlert } from '../utils/jalerts';

export default {
  components: {
    InputSearch
  },
  props: {
    activity_id: String,
  },
  watch: {
    inserting() {
      this.clearForm();
    }
  },
  data() {
    return {
      inserting: false,
      associate: '',
    }
  },
  methods: {
    saveAssociate() {
		let activityAssociate = {
			associate_id: this.associate,
			activity_id: this.activity_id,
			status: 'active'
		};
		ApiAxios.post(`activityAssociates`, { activityAssociate })
			.then(() => {
				this.clearForm();
				this.inserting = false;
				this.$emit('saved');
			})
			.catch((e) => {
				if (e.response.status == 403) {
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
    },
    clearForm() {
      this.associate = '';
    }
  }
}
</script>
